@import 'artpool.module';

@media screen and (max-width: $medium) {
  .root {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
  }
}

@media screen and (min-width: $medium) {
  .root {
    text-transform: uppercase;
    font-size: 14px;
    margin: 0;
  }
}

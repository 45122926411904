@import 'artpool.module';

.error,
.success {
  width: 100%;
}

.text {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 1;
  min-height: 32px;
  padding: 12px 16px;
}

.error .text {
  background-color: rgb(245, 188, 188);
  color: rgb(102, 0, 0);
}

.success .text {
  background-color: $PRIMARY_ARTPOOL_GREEN;
  color: rgb(102, 0, 0);
}

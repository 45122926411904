@import 'artpool.module';

.img {
  height: 365px;
  width: 100%;
  object-fit: cover;
  clip-path: $CLIP_PATH;
  &:hover {
    opacity: 0.7;
  }
}

.no_img {
  background-color: $NEUTRAL_50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hover_logo {
  font-size: 40px;
  transition: 0.2s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

.img_container:hover .hover_logo {
  opacity: 1;
}

.container {
  width: 450px;
}

@media screen and (max-width: $medium) {
  .container {
    max-width: 95vw;
  }
}

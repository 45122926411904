@import 'artpool.module';

@media screen and (max-width: $medium) {
  .subtitle1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
  }
}

@media screen and (min-width: $medium) {
  .subtitle1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0px;
  }
}

@import 'artpool.module';

.root {
  color: $NEUTRAL_900;
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
}
.colors {
  opacity: 0.5;
}
.container {
  background-color: white;
  width: 100%;
}

.centered {
  @media screen and (min-width: $medium) {
    justify-content: center;
  }
}

.fixed {
  z-index: 100;
  position: fixed;
  //top: $MENU_HEIGHT;
  top: 0;
  overflow: scroll;
}

.selected {
  color: black;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 3px;
  text-decoration-color: $PRIMARY_ARTPOOL_GREEN;
  border-radius: 8px 8px 0px 0px;
  background-color: $NEUTRAL_50;
}

.indicator {
  display: none;
}
.wrapper {
  border-bottom: 2px solid black;
}

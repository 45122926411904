@import 'artpool.module';

$card-width: 370px;

.image {
  object-fit: cover;
  height: 280px;
}
.content {
  padding: 30px 15px;
  background-color: $WHITE;
  height: 330px;
}

.container {
  width: $card-width;
}

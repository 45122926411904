@import 'artpool.module';

$DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN: 24px;
$DEFAULT_PAGE_HORIZONTAL_PADDING_MEDIUM_SCREEN: 140px;

$BANNER_CONTENT_HORIZONTAL_PADDING_MEDIUM_SCREENS: 50px;
$BANNER_CONTENT_HORIZONTAL_PADDING_LARGE_SCREENS: 70px;

$BANNER_IMAGE_WIDTH_MEDIUM_SCREEN: 190px;
$BANNER_IMAGE_MARGIN_RIGHT_MEDIUM_SCREEN: 50px;

$BANNER_IMAGE_WIDTH_LARGE_SCREEN: 190px;
$BANNER_IMAGE_MARGIN_RIGHT_LARGE_SCREEN: 105px;

.title {
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.18px;
  margin-bottom: 24px;
  color: $LIGHTER_BLACK;
}

.aboutPage p {
  font-family: 'Replica-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: $LIGHTER_BLACK;
}

.aboutPage p ~ p {
  margin-top: 24px;
}

.container {
  padding: 0 $DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN;
}

.banner {
  background-color: $NEUTRAL_300;
  padding: 0 $DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN;
  display: flex;
  align-items: flex-end;
}

.banner p {
  font-family: 'Replica-Regular';
  color: $LIGHTER_BLACK;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  padding: 40px 0;
  flex: 1
}

.bannerImage {
  flex-shrink: 0;
  margin-right: 48px;
  height: 150px;
  width: 150px;
  background-color: $PRIMARY_ARTPOOL_GREEN;
  margin-bottom: -24px;
}

.bannerImage img {
  height: 100%;
  width: 100%;
}

.aboutArtpool {
  padding: 40px $DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN;
}

.banner p,
.aboutArtpool p {
  max-width: 890px;
}

.meetTeam {
  padding: 32px $DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN 0;
  background-color: $PRIMARY_ARTPOOL_GREEN;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.carousel {
  margin-bottom: -80px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.getInTouch {
  background-color: $NEUTRAL_50;
  clip-path: $CLIP_PATH;
  padding: 50px $DEFAULT_PAGE_HORIZONTAL_PADDING_SMALL_SCREEN 100px;
}

.getInTouch .content {
  display: grid;
  row-gap: 32px;
  max-width: 950px;
  width: 100%;
}

.getInTouch h2 {
  font-weight: 700;
  font-size: 34px;
  line-height: 48px;
  letter-spacing: 0.18px;
  color: $LIGHTER_BLACK;
}

.getInTouch .content p {
  color: #707070;
}

.socialIcons {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.socialIcon {
  font-family: 'Replica-Regular';
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.18px;
  color: #1155cc;
  outline: none;
  max-width: fit-content;

  svg {
    fill: #707070;
    height: 40px;
    width: 40px;
    margin-right: 24px;
  }
}

@media screen and (min-width: $medium) {
  .title {
    font-size: 42px;
    line-height: 56px;
    margin-bottom: 72px;
  }

  .aboutPage p {
    font-size: 24px;
    line-height: 34px;
  }

  .aboutPage p ~ p {
    margin-top: 24px;
  }

  .container {
    padding: 0 $DEFAULT_PAGE_HORIZONTAL_PADDING_MEDIUM_SCREEN;
  }

  .banner {
    padding: 50px $BANNER_CONTENT_HORIZONTAL_PADDING_MEDIUM_SCREENS 0;
  }

  .banner p {
    padding: 0;
    font-size: 34px;
    line-height: 44px;
    align-self: flex-start;
  }

  .bannerImage {
    height: $BANNER_IMAGE_WIDTH_MEDIUM_SCREEN;
    width: $BANNER_IMAGE_WIDTH_MEDIUM_SCREEN;
    margin-right: $BANNER_IMAGE_MARGIN_RIGHT_MEDIUM_SCREEN;
    margin-bottom: -66px;
  }

  .aboutArtpool {
    padding-left: calc(
      $BANNER_CONTENT_HORIZONTAL_PADDING_LARGE_SCREENS +
        $BANNER_IMAGE_WIDTH_MEDIUM_SCREEN +
        $BANNER_IMAGE_MARGIN_RIGHT_MEDIUM_SCREEN
    );
  }

  .meetTeam {
    padding: 32px 20px 0;
  }

  .getInTouch {
    padding: 50px $DEFAULT_PAGE_HORIZONTAL_PADDING_MEDIUM_SCREEN 100px;
  }

  .getInTouch .content {
    row-gap: 40px;
  }

  .getInTouch h2 {
    font-size: 48px;
    line-height: 56px;
  }

  .socialIcons {
    flex-direction: row;
    row-gap: 0;
    column-gap: 60px;
  }
}

@media screen and (min-width: $large) {

  .banner {
    padding: 50px $BANNER_CONTENT_HORIZONTAL_PADDING_LARGE_SCREENS 0;
  }

  .bannerImage {
    width: $BANNER_IMAGE_WIDTH_LARGE_SCREEN;
    height: $BANNER_IMAGE_WIDTH_LARGE_SCREEN;
    margin-right: $BANNER_IMAGE_MARGIN_RIGHT_LARGE_SCREEN;
  }

  .aboutArtpool {
    padding-left: calc(
      $BANNER_CONTENT_HORIZONTAL_PADDING_LARGE_SCREENS +
        $BANNER_IMAGE_WIDTH_LARGE_SCREEN +
        $BANNER_IMAGE_MARGIN_RIGHT_LARGE_SCREEN
    );
  }
}

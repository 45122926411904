@import 'artpool.module';

.primary,
.secondary,
.tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 52px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: 100%;
  border: none;
  transition: opacity 0.4s ease-in-out, filter 0.4s ease-in-out;

  &:disabled {
    pointer-events: none;
    opacity: 0.9;
  }

  &:hover {
    filter: brightness(0.8);
  }

  span + svg {
    margin-left: 10px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.primary {
  color: $LIGHTER_BLACK;
  background-color: $PRIMARY_ARTPOOL_GREEN;
}

.secondary {
  color: $WHITE;
  background-color: $LIGHTER_BLACK;
}

.tertiary {
  color: $LIGHTER_BLACK;
  background-color: $WHITE;
}

@media screen and (min-width: $medium) {
  .primary,
  .secondary,
  .tertiary {
    padding: 0px 70px;
    max-width: fit-content;
  }
}

@import 'artpool.module';

// Todo fix - ACG only gets the classes with the !important property.
.root {
  font-family: Replica-Regular, Replica !important;
  text-transform: Capitalize !important;
  font-weight: bold !important;
  box-shadow: none !important;
}

.padding_override {
  padding: 10px 40px !important;
}

.text {
  color: white;

  &:hover {
    color: $PRIMARY_ARTPOOL_GREEN;
  }
}

.text--black {
  color: black;

  &:hover {
    color: $PRIMARY_ARTPOOL_GREEN;
    background-color: black;
  }
}

.text--green {
  color: $PRIMARY_ARTPOOL_GREEN;

  &:hover {
    color: black;
  }
}

.contained--black {
  background-color: black !important;
  color: white !important;

  &:hover {
    background-color: $PRIMARY_ARTPOOL_GREEN !important;
    color: black !important;
  }
}

.contained--green {
  background-color: $PRIMARY_ARTPOOL_GREEN !important;
  color: black !important;

  &:hover {
    background-color: black !important;
    color: white !important;
  }
}

.contained--pink {
  background-color: $ALTERNATIVE_ARTPOOL_PINK !important;
  color: white !important;

  &:hover {
    background-color: black !important;
    color: white !important;
  }
}

.outlined--black {
  color: black !important;
  border-color: black !important;

  &:hover {
    background-color: $PRIMARY_ARTPOOL_GREEN !important;
    border-color: $PRIMARY_ARTPOOL_GREEN !important;
  }
}

.outlined--green {
  color: $PRIMARY_ARTPOOL_GREEN !important;
  border-color: $PRIMARY_ARTPOOL_GREEN !important;

  &:hover {
    background-color: black !important;
    border-color: black !important;
    color: white !important;
  }
}

.outlined--pink {
  color: $ALTERNATIVE_ARTPOOL_PINK !important;
  border-color: $ALTERNATIVE_ARTPOOL_PINK !important;

  &:hover {
    background-color: $ALTERNATIVE_ARTPOOL_PINK !important;
    border-color: $ALTERNATIVE_ARTPOOL_PINK !important;
    color: white !important;
  }
}

.disabled {
  opacity: 0.7;
}

@media screen and (max-width: $medium) {
  .button_two {
    font-size: 14px !important;
  }
}

@media screen and (min-width: $medium) {
  .button_two {
    font-size: 16px !important;
  }
}

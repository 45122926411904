@import 'artpool.module';

@media screen and (max-width: $medium) {
  .root {
    font-size: 20px;
    margin: 0;
    line-height: 1.3;
  }
}

@media screen and (min-width: $medium) {
  .root {
    font-size: 24px;
    margin: 0;
  }
}

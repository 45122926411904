@import 'artpool.module';

.container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blur {
  width: 100%;
  height: 0.5em;
  position: absolute;
  bottom: 0em;
  filter: blur(3px);
}

// TODO - Create an artpool global css
.link {
  padding-top: 15px;
  cursor: pointer;
  color: $NEUTRAL_600;
  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

@import 'artpool.module';

.card {
  background-color: transparent;
  color: $PRIMARY_ARTPOOL_GREEN;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  flex-direction: column;
  width: 100%;
  width: 290px;
  height: 100%;
}

.imageContainer {
  height: 300px;
  width: 100%;
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 140px;
  height: 100%;
  background: $NEUTRAL_800;
  padding: 12px 24px 24px;
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );
}

.name {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
}

.role {
  font-family: 'Replica-Regular';
  display: block;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $WHITE;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.socialIcons a {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
}

.socialIcons a:not(:last-child) {
  margin-right: 22px;
}

@media screen and (min-width: $medium) {
  .card {
    max-width: 320px;
  }

  .name {
    font-size: 24px;
    line-height: 36px;
  }

  .role {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .imageContainer {
    height: 328px;
  }

  .contentContainer {
    min-height: 150px;
    padding: 12px 24px;
  }

  .socialIcons a {
    height: 22px;
    width: 22px;
  }
}

@import 'artpool.module';

$height: 360px;
$width: 100%;
$max-width: 300px;

.image_input {
  height: $height;
  width: $width;
  filter: blur(2px);
  box-sizing: border-box;
  max-width: $max-width;
  background-color: $NEUTRAL_300;
  border-radius: 6px;
  overflow: hidden;
}

.image_input_text {
  cursor: pointer;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0px;
  left: 0px;
  height: $height;
  width: $width;
  max-width: $max-width;
  border: 1px dashed gray;
  border-radius: 6px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.input_parent {
  position: relative;
  width: 100%;

  & input::file-selector-button {
    background-color: transparent;
    color: transparent;
    border: 0px;
    cursor: pointer;
    height: $height;
    width: $width;
    max-width: $max-width;
  }
}

.btn_input {
  width: 300px;
  height: 360px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

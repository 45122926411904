@import 'artpool.module';

.container {
  text-align: center;
}

.links_section {
  margin: 25px 0px;
  width: 100%;
  height: 130px;
}

.links_container {
  text-align: left;
  height: 100%;
}

// TODO - take this style to a general styles library
.icon {
  color: $NEUTRAL_600;
  &:hover {
    color: $PRIMARY_ARTPOOL_GREEN;
    background-color: transparent;
  }
}

.copyright {
  width: 100%;
  display: inline-block;
}

.logo_artpool {
  margin-bottom: 15px;
  width: 270px;
}

.logo_acg_horizontal {
  margin-left: 10px;
  width: 110px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  position: relative;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) -1px 2px 1px);
}

.item {
  width: 20px;
  height: 20px;
  flex-basis: 10%;
  clip-path: polygon(
    3px 0%,
    calc(100% - 3px) 0%,
    100% 3px,
    100% calc(100% - 3px),
    calc(100% - 3px) 100%,
    3px 100%,
    0% calc(100% - 3px),
    0% 3px
  );
}

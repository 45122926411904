@import 'artpool.module';

.chip {
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
}

.chip_color {
  color: black;
  background-color: $NEUTRAL_50;
}

.popper {
  & li {
    cursor: pointer;
    padding: 10px;

    &:hover {
      background-color: $NEUTRAL_50;
    }
  }
}

@import 'artpool.module';

.container {
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.arrow_container {
  background-color: $NEUTRAL_50;
  border-radius: 4px;
  padding: 5px;
}

$PRIMARY_ACG_PURPLE_50: #f1e8ff;
$PRIMARY_ACG_PURPLE_200: #cab8ff;
$PRIMARY_ACG_PURPLE_500: #6f32ff;
$PRIMARY_ARTPOOL_GREEN: #71ff6e;

$SECONDARY_HYPERLINK: #1155cc;

$ALTERNATIVE_ARTPOOL_BLUE: #adfffe;
$ALTERNATIVE_ARTPOOL_YELLOW: #fbf563;
$ALTERNATIVE_ARTPOOL_ORANGE: #ee8e5f;
$ALTERNATIVE_ARTPOOL_PINK: #fb6cfd;

$POSITIVE_50: #ecf8f2;
$POSITIVE_300: #b3e5ca;
$POSITIVE_500: #3aa76d;
$POSITIVE_700: #2e8456;
$POSITIVE_900: #1a4c31;

$ALERT_50: #fff7f0;
$ALERT_300: #fcca9c;
$ALERT_500: #f78013;
$ALERT_700: #c56207;
$ALERT_900: #763a03;

$NEGATIVE_50: #feecec;
$NEGATIVE_300: #f57070;
$NEGATIVE_500: #f02828;
$NEGATIVE_700: #a70c0c;
$NEGATIVE_900: #5f0707;

$NEUTRAL_50: #f6f6f6;
$NEUTRAL_100: #f1f1f1;
$NEUTRAL_200: #ebebeb;
$NEUTRAL_300: #e6e6e6;
$NEUTRAL_400: #cccccc;
$NEUTRAL_500: #999999;
$NEUTRAL_600: #707070;
$NEUTRAL_700: #5d5d5d;
$NEUTRAL_800: #3e3e3e;
$NEUTRAL_900: #1d1d1d;

$BLACK: #000000;
$LIGHTER_BLACK: lighten($BLACK, 10%);
$WHITE: #ffffff;
$MENU_HEIGHT: 72px;

$XSmall: 0px;
$small: 321px;
$medium: 769px;
$large: 1154px;
$XLarge: 1400px;

$CLIP_PATH: polygon(
  8px 0%,
  calc(100% - 8px) 0%,
  100% 8px,
  100% calc(100% - 8px),
  calc(100% - 8px) 100%,
  8px 100%,
  0% calc(100% - 8px),
  0% 8px
);

:export {
  PRIMARY_ACG_PURPLE_50: $PRIMARY_ACG_PURPLE_50;
  PRIMARY_ACG_PURPLE_200: $PRIMARY_ACG_PURPLE_200;
  PRIMARY_ACG_PURPLE_500: $PRIMARY_ACG_PURPLE_500;
  PRIMARY_ARTPOOL_GREEN: $PRIMARY_ARTPOOL_GREEN;

  SECONDARY_HYPERLINK: $SECONDARY_HYPERLINK;

  ALTERNATIVE_ARTPOOL_BLUE: $ALTERNATIVE_ARTPOOL_BLUE;
  ALTERNATIVE_ARTPOOL_YELLOW: $ALTERNATIVE_ARTPOOL_YELLOW;
  ALTERNATIVE_ARTPOOL_ORANGE: $ALTERNATIVE_ARTPOOL_ORANGE;
  ALTERNATIVE_ARTPOOL_PINK: $ALTERNATIVE_ARTPOOL_PINK;

  POSITIVE_50: $POSITIVE_50;
  POSITIVE_300: $POSITIVE_300;
  POSITIVE_500: $POSITIVE_500;
  POSITIVE_700: $POSITIVE_700;
  POSITIVE_900: $POSITIVE_900;

  ALERT_50: $ALERT_50;
  ALERT_300: $ALERT_300;
  ALERT_500: $ALERT_500;
  ALERT_700: $ALERT_700;
  ALERT_900: $ALERT_900;

  NEGATIVE_50: $NEGATIVE_50;
  NEGATIVE_300: $NEGATIVE_300;
  NEGATIVE_500: $NEGATIVE_500;
  NEGATIVE_700: $NEGATIVE_700;
  NEGATIVE_900: $NEGATIVE_900;

  NEUTRAL_50: $NEUTRAL_50;
  NEUTRAL_100: $NEUTRAL_100;
  NEUTRAL_200: $NEUTRAL_200;
  NEUTRAL_300: $NEUTRAL_300;
  NEUTRAL_400: $NEUTRAL_400;
  NEUTRAL_500: $NEUTRAL_500;
  NEUTRAL_600: $NEUTRAL_600;
  NEUTRAL_700: $NEUTRAL_700;
  NEUTRAL_800: $NEUTRAL_800;
  NEUTRAL_900: $NEUTRAL_900;

  BLACK: $BLACK;
  LIGHTER_BLACK: $LIGHTER_BLACK;
  WHITE: $WHITE;

  X_SMALL: $XSmall;
  SMALL: $small;
  MEDIUM: $medium;
  LARGE: $large;
  X_LARGE: $XLarge;

  MENU_HEIGHT: $MENU_HEIGHT;
  CLIP_PATH: $CLIP_PATH;
}

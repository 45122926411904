@import 'artpool.module';

.logo_cross {
  position: absolute;
  left: 0;
  cursor: pointer;
}
.logo_artpool {
  width: 100px;
  margin-bottom: 30px;
}

.drawer {
  width: 100vw;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.listItem {
  margin: 10px;
  width: fit-content;
  margin: 21px;
}

.drawer {
  background-color: white;
}

.burgerButton {
  &:hover {
    background-color: $PRIMARY_ACG_PURPLE_50;
  }
}

@import 'artpool.module';

@media screen and (max-width: $medium) {
  .root {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}

@media screen and (min-width: $medium) {
  .root {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
}

@import 'artpool.module';

.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  width: 100%;
}

.answer {
  margin-top: 16px;
  width: 100%;
}

.summary {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  list-style-type: none;
}

.summary::-webkit-details-marker {
  display: none;
}

.summary svg {
  height: 32px;
  width: 32px;
  flex-shrink: 0;
}

.details {
  padding: 16px 0;
}

.details:not(:last-child) {
  border-bottom: 1px solid $NEUTRAL_300;
}

.details p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.details p,
.details ul {
  margin-bottom: 16px;
}

.details[open] .summary svg {
  transform: rotate(180deg);
}

.details ul {
  display: grid;
  row-gap: 16px;
}

.details ul li {
  display: flex;
  align-items: flex-start;
}

@media screen and (min-width: $large) {
  .container {
    row-gap: 40px;
  }

  .summary {
    align-items: center;
    font-size: 34px;
    line-height: 48px;
  }

  .summary svg {
    height: 32px;
    width: 32px;
  }

  .details p {
    font-size: 20px;
    line-height: 36px;
  }
}

@import 'artpool.module';

.overlay {
  align-items: flex-start;
  background: #fafafa9e;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999;
}

.overlay:before {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='texture' data-v-1d260e0e=''%3E%3Cfilter id='noise' data-v-1d260e0e=''%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.8' numOctaves='4' stitchTiles='stitch' data-v-1d260e0e=''%3E%3C/feTurbulence%3E%3CfeColorMatrix type='saturate' values='0' data-v-1d260e0e=''%3E%3C/feColorMatrix%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noise)' data-v-1d260e0e=''%3E%3C/rect%3E%3C/svg%3E");
  opacity: 0.35;
  height: 100%;
  position: absolute;
  width: 100%;
}

.modal {
  background-color: $WHITE;
  margin-top: 80px;
  max-width: 30rem;
  overflow-y: scroll;
  width: 100%;
  z-index: 1000;
}

.header {
  background: $WHITE;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.closeButton {
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 7px;
}

.logo {
  $logoSize: 70px;
  height: 70px;
  width: 70px;
}

.text {
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}

.description {
  text-align: center;
}

.tab {
  font-size: 16px;
  text-transform: none;
  color: #1a1a1a;
  font-weight: bold;
  padding: 12px 16px;
}

.bodyContainer {
  background: $WHITE;
}

.formContainer {
  padding: 32px;
}

.title {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
}

.link {
  color: $NEUTRAL_700;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 14px;
  line-height: 25px;
  transition: color 0.2s ease-in-out;
}

.link:hover {
  color: $NEUTRAL_500;
}

.buttonPrimary,
.buttonSecondary {
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  outline: none;
  padding: 10px 40px;
  transition: filter 0.2s ease-in-out;
  width: 100%;
}

.buttonPrimary:disabled,
.buttonSecondary:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.buttonPrimary:hover,
.buttonSecondary:hover {
  filter: brightness(1.1);
}

.buttonPrimary {
  background-color: $PRIMARY_ARTPOOL_GREEN;
  font-weight: 700;
  border: none;
  color: $BLACK;
}

.buttonPrimary:disabled {
  background-color: $NEUTRAL_400;
}

.buttonSecondary {
  background-color: transparent;
  border: 0.5px solid $NEUTRAL_700;
  color: $NEUTRAL_700;
  font-weight: normal;
}

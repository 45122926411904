@import 'artpool.module';

.container {
  height: $MENU_HEIGHT;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.logo {
  flex-grow: 1;
  cursor: pointer;
}

.text_menu {
  padding: 0 16px;
}

.login_text {
  padding-right: 5px;
}

.auth_item {
  position: absolute;
  top: 25px;
  right: 16px;
  cursor: pointer;
  color: $NEUTRAL_600;
}

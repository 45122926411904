@import 'artpool.module';

.supporters {
  padding: 100px 24px;
}

.supporters h4 {
  color: $NEUTRAL_600;
  font-weight: bold;
  text-align: center;
  padding: 30px;
}

.supporters div {
  padding: 20px;
  text-align: center;
}

.supporters svg {
  fill: black;
  text-align: center;
  &:hover {
    fill: $PRIMARY_ARTPOOL_GREEN;
  }
}

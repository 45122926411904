@import 'artpool.module';

.container {
  padding: 40px;
  cursor: pointer;
  height: 100%;
  clip-path: $CLIP_PATH;
  &:hover {
    opacity: 0.85;
  }
}

.hovered {
  &:hover {
    opacity: 1;
    background-color: $ALTERNATIVE_ARTPOOL_BLUE !important;
  }
}

.link {
  cursor: pointer;

  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

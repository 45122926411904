@import 'artpool.module';

@media screen and (max-width: $medium) {
  .root {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }
}

@media screen and (min-width: $medium) {
  .root {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
}

.main_image {
  width: 100%;
  height: 354px;
  object-fit: cover;
}

.small_image {
  object-fit: cover;
  width: 120px;
  height: 80px;
}

.image_container {
  max-width: 100%;
  display: flex;
}

.image_item {
  width: 33.3%;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}

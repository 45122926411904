@import 'artpool.module';

.titleText {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:hover {
    color: $PRIMARY_ARTPOOL_GREEN;
  }
}

.banner {
  background-color: $LIGHTER_BLACK;
  color: $WHITE;
}

.bannerWhite {
  background-color: $WHITE;
  color: black;
  position: relative;
}

.bannerContent {
  display: flex;
  flex-direction: column;
  padding: 32px 16px 56px;
  line-height: 1.2;
}

@media screen and (min-width: $medium) {
  .banner {
    & {
      span,
      label,
      p,
      h1 {
        text-align: left;
      }
    }
  }

  .bannerContent {
    align-items: flex-start;
    padding: 30px 96px;
  }
}

@media screen and (min-width: $large) {
  .banner {
    height: calc(100vh - $MENU_HEIGHT - 50px);
  }

  .bannerWhite {
    height: calc(100vh - $MENU_HEIGHT - 50px);
  }
}

@import 'artpool.module';

// TODO - Create an artpool global css (class repeated it text-overflow-container.tsx)
.link {
  padding-top: 15px;
  cursor: pointer;
  color: black;
  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

@import 'artpool.module';

.menu_margin {
  margin-top: $MENU_HEIGHT;
}

.icon {
  cursor: pointer;
  color: $NEUTRAL_600;
  font-size: 25px;

  &:hover {
    color: $PRIMARY_ARTPOOL_GREEN;
    background-color: transparent;
  }
}

.link {
  padding-top: 15px;
  cursor: pointer;
  color: $NEUTRAL_600;

  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

.link--white {
  padding-top: 15px;
  cursor: pointer;
  color: white;

  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

.link--blue {
  padding-top: 15px;
  cursor: pointer;
  color: $SECONDARY_HYPERLINK;

  &:hover {
    color: $ALTERNATIVE_ARTPOOL_PINK;
  }
}

.nav_link {
  color: gray;
  &:hover {
    color: black;
    padding-bottom: 2px;
    border-bottom: 4px solid $PRIMARY_ARTPOOL_GREEN;
  }
}

.title_link {
  &:hover {
    color: black;
    padding-bottom: 2px;
    border-bottom: 4px solid $PRIMARY_ARTPOOL_GREEN;
  }
}

.clipped_corners {
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );
}

.error {
  color: $ALTERNATIVE_ARTPOOL_ORANGE;
  font-size: 12px;
}

.error--orange {
  padding-top: 5px;
  color: $ALTERNATIVE_ARTPOOL_ORANGE;
  font-size: 12px;
}

.success--pink {
  padding-top: 5px;
  color: $ALTERNATIVE_ARTPOOL_PINK;
  font-size: 12px;
}

.tag {
  padding: 5px 12px;
  background-color: #3e3e3e;
  opacity: 0.7;
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 15px;
  border-radius: 16px;
  color: white;
}

.modal {
  position: absolute;
  background-color: white;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 800px;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

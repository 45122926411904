@import 'artpool.module';

@media screen and (max-width: $medium) {
  .body1 {
    font-size: 16px;
    line-height: 1.5em;
    white-space: pre-wrap;
    margin: 0px;
  }
}

@media screen and (min-width: $medium) {
  .body1 {
    font-size: 18px;
    line-height: 1.5em;
    white-space: pre-wrap;
    margin: 0px;
  }
}
